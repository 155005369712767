import BasePlugin from '../BasePlugin'

export default class ExportProjectSolutionFromSolution extends BasePlugin {
  async execute () {
    let me = this
    let card = this.context.getCard()
    let context = this.context
    let solutionId = card.data.id
    card.setLoading(true)
    this.context.$http({
      method: 'get',
      url: `${this.context.$config.api}/registryservice/registry/4741/data?filter[0]=attr_4743_,eqx,${solutionId}&offset=0&limit=100000&search_value=&search_fields=attr_4746_`
    }).then(function (response) {
      let ids = []
      response.data.data.forEach(function (item) {
        ids.push(item.id)
      })
      context.$http({
        method: 'post',
        url: `${context.$config.api}/etleditor/export/120`,
        data: {
          'in': {
            'id':
            ids
          }
        },
        responseType: 'arraybuffer'
      }).then(function (response2) {
        card.setLoading(false)
        me.openExportAttachment(response2.data, me, 'xlsx')
      }, (response2) => { card.setLoading(false) })
    }, (response) => { card.setLoading(false) })
  }
}
